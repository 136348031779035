import React from "react";
import { motion } from "framer-motion";

const AnimatedKong = ({ className }) => {
    return (
        <svg id="b" data-name="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.42 98.44" className={className}>
        <g id="c" data-name="Layer_1">
            <g>
            <path d="M45.9.17c-4.63.82-8.63,2.88-12.1,6.2-2.24,2.15-4.05,4.5-8.07,10.44-2.46,3.64-4.35,6.32-5.23,7.4-.35.42-.62.78-.62.81s.9.1,2,.14c7.33.32,13.52,3.27,17.87,8.49,2.52,3.01,4.11,6.28,5.21,10.63,1.34,5.35,2.78,8.11,5.8,11.15,1.63,1.63,2.86,2.54,4.63,3.43,2.4,1.19,4.76,1.79,7.52,1.92,3.35.16,6.22-.46,9.24-2,1.19-.62,1.69-.98,2.7-2,1.63-1.6,2.2-2.67,2.21-4.12.01-1.19-.29-1.87-1.17-2.69-.81-.77-1.33-1.08-2.52-1.56-1.97-.78-3.7-1.04-7.87-1.17-2.69-.09-4.01-.19-4.6-.35-2.78-.77-4.06-2.3-3.8-4.53.16-1.42,1.06-2.66,2.76-3.88l.87-.62-.04-1.3-.04-1.3-1.4-.17c-3.82-.48-5.51-1.43-6.54-3.66-1.58-3.37-.22-8.04,2.95-10.14,2.31-1.53,5.28-1.68,9.38-.43,3.46,1.03,3.92,1.13,5.31,1.13.9,0,1.65-.09,2.17-.25,1.27-.39,1.3-.43.72-1.04-.27-.29-.97-.88-1.52-1.3-.56-.42-1.1-.85-1.2-.97-.09-.12-.43-.98-.77-1.94-1.65-4.8-3.59-8.01-6.48-10.79-2.81-2.7-5.88-4.38-9.7-5.28-1.89-.45-5.9-.59-7.66-.27ZM45.66,14.28c1.27.48,2.56,1.75,3.28,3.24.8,1.62.87,2.3.36,3.48-.22.52-.43.94-.49.94-.04,0-.27-.46-.49-1.01-.94-2.31-2.7-3.9-4.35-3.9-1.23,0-2.49,1.2-3.07,2.94-.38,1.14-.23,2.98.33,4.08.81,1.59,2.24,2.59,4.02,2.81l.87.1-.87.19c-1.24.27-2.98.14-3.98-.3-.95-.43-2.4-1.72-3.04-2.72-1.3-2.04-1.36-4.87-.14-7.01.82-1.43,2.49-2.72,3.99-3.09.9-.23,2.63-.09,3.57.27Z"/>
            <motion.path  animate={{ scaleY : [1, 0.1, 1], scaleX : [1, 1.2, 1], transition : { duration : 0.2, repeatType : "reverse", repeat : Infinity, repeatDelay : Math.floor(Math.random() * (3000 - 2000 + 1) + 2000) / 1000, ease : "easeInOut" }}} d="M60.57,24.48c-2.43.52-4.25,2.36-4.25,4.29,0,.98.4,1.79,1.32,2.65.82.78,2.33,1.52,3.25,1.63.56.06.74,0,1.26-.38.78-.55,2.17-1.26,2.96-1.49.74-.22.91-.14,2.26.87.55.42,1.01.74,1.03.71s.25-.45.49-.95c.25-.49.53-.9.64-.91.35-.03,1.33.03,1.55.09.16.04.32-.2.55-.84.45-1.16,1.33-2.67,2.24-3.83.39-.51.69-.94.67-.97s-.74.17-1.58.45c-2.27.75-3.22.72-5.21-.13-1.92-.82-2.72-1.07-3.98-1.26-1.32-.19-2.11-.16-3.2.07Z"/>
            <path d="M18.2,28.37s-.1.02-.19.04c-3.36.63-5.74,1.89-6.47,2.29-.81.45-2.77,1.58-4.8,3.63-3.09,3.12-5.1,6.9-6.03,11.35-.51,2.5-.59,5.45-.49,18.05v11.78l.7-.88c2.78-2.99,6.65-5.09,10.93-5.93,1.79-.35,5.74-.35,7.49,0,3.83.77,7.84,2.99,10.54,5.86,2.11,2.26,3.86,5.28,4.81,8.34.9,2.86.97,3.5,1.22,9.51v5.2h7.14c7.97.25,8.27.22,9.95-.63,1.87-.94,3.09-2.65,3.47-4.83.25-1.47.09-4.02-.38-5.78-.68-2.63-2.63-6.23-4.51-8.31-.46-.52-.74-.87-.62-.78,1.36.97,2.91,2.28,3.9,3.33,4.34,4.53,6.59,9.64,7.03,15.92l.1,1.93h9.48c9.06,0,9.94-.06,12.42-.87,5.47-1.75,8.22-6.7,8.5-15.31.24-7.2-1.44-14.83-4.93-22.35-1.24-2.71-4.25-7.53-4.45-7.17-.07.1-.13.29-.13.43,0,.4-1.26,2.82-1.94,3.73-2.69,3.62-7.41,6.37-12.47,7.29-.74.13-2.25.21-4.5.21h-3.4l.91,1.18c4.01,5.22,6.04,11.59,5.75,18.16-.1,2.46-.26,3.66-.72,5.51l-.32,1.32-.09-2.13c-.1-2.5-.18-3.15-.68-5.51-.82-3.98-2.71-8.47-4.93-11.78-3.75-5.56-9.57-10.25-16.2-13.07-4.5-1.91-8.76-2.98-13.97-3.54-2.31-.25-8.41-.35-10.31-.16l-1.03.09.88-.28c1.4-.46,5.25-1.28,7.5-1.6,2.79-.41,6.65-.63,8.97-.53,1.6.07,5.85.44,6.44.56.13.01.19-.44.22-1.59.12-3.93-.82-7.98-2.71-11.69-2.87-5.63-7.75-9.41-13.95-10.76-1.73-.38-6.51-.5-8.16-.21Z"/>
            <path d="M71.45,33.96c-.48.29-.72.98-.64,1.88.09.69.46,2.11.62,2.28.03.03.39-.16.8-.43.87-.55,1.58-1.56,1.58-2.24,0-1.04-1.49-2-2.36-1.49Z"/>
            <path d="M64.56,34.34c-1.3.68-.77,2.13,1.13,3.02,1.5.71,4.51,1.23,4.51.78,0-.09-.19-.52-.42-.98-1.07-2.1-3.79-3.56-5.22-2.82Z"/>
            <path d="M71.43,45.58c-3.21-.29-10.97-1.45-11.21-1.66-.07-.07.43-.13,1.37-.14.84,0,4.02-.03,7.08-.04,5.94-.04,6.91-.14,7.85-.82.64-.46,1.11-.07,1.11.95,0,.61-.59,1.13-1.63,1.45-.75.23-3.37.38-4.58.27Z"/>
            <path d="M12,73.39c-2.08.42-3.7,1.08-5.38,2.18-3.02,1.95-5.13,4.96-6.06,8.56-.49,1.88-.62,4.44-.53,9.46l.09,4.02h10.86q10.86-.01,11.81-.33c2.86-.98,5.36-3.63,6.26-6.68.48-1.59.55-2.05.53-3.82-.03-6.26-4.96-11.97-11.51-13.33-1.52-.32-4.68-.35-6.07-.06Z"/>
            </g>
        </g>
        </svg>
    )
}

export default AnimatedKong