import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './components/layout/Layout';

function App() {
  const location = useLocation();
  return (
  <Layout>
      <Routes location={location} key={location.pathname}>
        <Route index element={<Home />} />
      </Routes>
  </Layout>
  );
}

export default App;
