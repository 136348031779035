import React, { useState, useEffect } from "react"
import { motion } from 'framer-motion';
import AnimatedKong from "../components/animation/AnimatedKong";
import { BsArrowDownCircle } from "react-icons/bs";



const LandingPage = () => {
    const [revealKong, setRevealKong] = useState(false);
    const [showScrollIndicator, setShowScrollIndicator] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            setShowScrollIndicator(scrollPosition < windowHeight * 0.1);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToNextSection = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <div className="min-h-screen items-center justify-start flex">
                <div className="flex items-start text-[130px] mx-20 text-white flex-col w-full uppercase font-montserrat font-black">
                    <motion.p initial={{ opacity: 0.2, x : "-120%", y : "-40%", filter: "blur(10px)"}} animate={{ opacity: 1, x : 0, y :0, filter: "blur(0px)"}} transition={{ duration: 0.7, ease: "easeOut", delay : 0.4, opacity : {delay : 0.3} }} className=" leading-none">Ready to</motion.p>
                    <div className="flex flex-row">
                        <motion.div
                            initial={{ y : "-400%", scaleX: 0.5, scaleY: 1.2 }}
                            animate={{ y: revealKong ? 0 : "-400%", scaleY: revealKong ? ["120%", "100%"] : "100%", scaleX: revealKong ? ["50%", "100%"] : "100%" }}
                            transition={{delay: 0.2, duration: 0.5, ease: "easeOut"}}
                            className="w-1/5 mx-4"
                        >
                            <AnimatedKong className="fill-white w-full" />
                        </motion.div>
                        <div className="flex flex-col">
                            <div className="items-center relative w-fit">
                                <motion.p initial={{ opacity: 0.2, x : "-120%", y : "30%", filter: "blur(10px)"}} animate={{ opacity: 1, x : 0, y :0, filter: "blur(0px)"}} transition={{ duration: 0.7, ease: "easeOut", delay : 0.2, opacity : {delay : 0.3} }} onAnimationComplete={() => setRevealKong(true)} className="leading-none">Build</motion.p>
                            <motion.div 
                                initial={{ scale: 1.3, pathLength: 0 }}
                                animate={{ scale: 1.3, pathLength: 1 }}
                                transition={{ duration: 0.5, delay: 0.7 }}
                                className="absolute inset-0 flex items-center justify-center"
                                style={{ transform: 'translate(-10%, 25%)' }}
                            >
                                {/* <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 542 210">
                                    <defs>
                                        <style>
                                        {`.cls-1 {
                                            fill: none;
                                            stroke: rgba(255, 255, 255);
                                            stroke-miterlimit: 10;
                                            stroke-width: 8px;
                                        }`}
                                        </style>
                                    </defs>
                                    <g id="Layer_2-2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1-2">
                                        <motion.path initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 0.5, delay: 0.7 }} class="cls-1" d="M394.75,187.53c22.72-5.13,44.71-10.24,66.56-19.07,29.83-12.46,61.74-28,74.39-57.59,13.3-34.76-18.9-61.03-47.96-73.39-51.14-21.79-107.55-25.86-162.64-28.22-83.37-2.47-169.36,2.98-245.95,37.89-21.01,9.72-44.1,23.07-57.84,40.81-13.38,16.44-17.04,39.12-4.63,57.65,39.51,57.82,168.63,64.83,234.15,60.32,63.64-4.14,155.93-17.01,205.91-58.25,32.32-26.58,37.82-67.78,8.87-98.96-23.19-25-57.59-36.03-90.68-42.08-89.19-14.54-183.42-.32-265.86,35.77-33.49,15.46-66.56,34.51-88.6,64.57C2.15,131.81.35,159.14,1.15,188.95"/>
                                        </g>
                                    </g>
                                </svg> */}
                            </motion.div>
                            </div>
                            <motion.p initial={{ opacity: 0.2, x : "-120%", y : "60%", filter: "blur(10px)"}} animate={{ opacity: 1, x : 0, y :0, filter: "blur(0px)"}} transition={{ duration: 0.7, ease: "easeOut", delay : 0.3, opacity : {delay : 0.3} }} className="leading-none">Your Ideas?</motion.p> 
                        </div>
                    </div>
                </div>
            </div>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: showScrollIndicator ? 1 : 0, y: showScrollIndicator ? 0 : 20 }}
                transition={{ duration: 0.3 }}
                className="fixed bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer"
                onClick={scrollToNextSection}
            >
                <BsArrowDownCircle className="text-white text-6xl animate-bounce" />
            </motion.div>
        </>
    )
};


const Home = () => {
    
    return (
        <>
            <LandingPage />
        </>
    );
}

export default Home;