import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Intro from "../animation/Intro";
import Navbar from "./Navbar";

const Layout = ({ children }) => {
  const location = useLocation();
  const [showIntro, setShowIntro] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      // setShowIntro(false);
      setShowIntro(true);
      const introTimer = setTimeout(() => {
        setShowIntro(false);
      }, 6300);
      return () => clearTimeout(introTimer);
    } else {
      setShowIntro(false);
    }
  }, [location.pathname]);

  const contentVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <AnimatePresence mode="wait">
      {showIntro ? (
        <Intro key="intro" />
      ) : (
        <>
          <Navbar />
          <motion.div
            key="content"
            initial="hidden"
            animate="visible"
            variants={contentVariants}
          >
            {children}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Layout;